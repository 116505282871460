import { FunctionComponent, ReactElement } from 'react';
import Image from 'next/image';
import { Box } from '@mui/material';

import StickyFooterLayout from '../layout/StickyFooterLayout';

import LoginHeader from './LoginHeader';

import Logo from '../../public/logo/stride-120.svg';

const LandingTemplate: FunctionComponent = ({
  children,
}) => (
  <StickyFooterLayout HeaderElement={<LoginHeader />}>
    <Box mt={2} textAlign='center'>
      <Image alt='Stride logo' src={Logo} />
    </Box>
    <Box display={'flex'} flexDirection='column' my={5} textAlign='center'>
      {children}
    </Box>
  </StickyFooterLayout>
);

export const getLandingTemplate = (page: ReactElement): ReactElement => (
  <LandingTemplate>{page}</LandingTemplate>
);
